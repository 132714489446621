import { createContext, useContext } from "react";
import { Geo } from "types/MapContext";
import { CombinedDatapoints } from "types/cube";
import { IShareProgress } from "./useProgressQuery";

interface FeedbackPopUpProps {
  geo: Geo;
  progressQuery?: IShareProgress;
  datapoint: CombinedDatapoints;
  geoCode: string;
  stateCode: string;
}

export const DownloadReportContext = createContext<FeedbackPopUpProps | null>(
  null,
);

const useDownloadReportContext = () => {
  const values = useContext(DownloadReportContext);

  if (!values) {
    throw new Error("Cannot use FeedbackPopUp outside of provider");
  }

  return values;
};

export default useDownloadReportContext;
