import axios, { AxiosResponse } from "axios";
import GeoNames from "lib/options/GeoNames";
import { Geo } from "types/MapContext";
import { CombinedDatapoints } from "types/cube";

export interface GenerateReportReqBody {
  geo: Geo;
  datapoint: CombinedDatapoints;
  geoCode: string;
  stateCode?: string;
  token: string;
}

export const createReportPayment = async (userId: string) => {
  const { data } = await axios.post<
    { userId: string },
    AxiosResponse<{
      data: {
        clientSecret: string;
        paymentIntentId: string;
      };
    }>
  >("/api/stripe/create-report-payment", { userId });
  return data.data;
};

export const fetchReport = async (
  body: GenerateReportReqBody,
  locationName: any,
) => {
  try {
    const fileName = getFileName(locationName);
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/generateReport`,
      body,
      {
        headers: { "Content-Type": "application/json" },
        responseType: "blob",
      },
    );
    if (res.status === 200) {
      const file = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = file;
      a.download = fileName;
      a.click();
    }
  } catch (error) {
    console.log(error);
    if (typeof error === "string") throw error;
    throw "Looks like something went wrong, please contact reventure support";
  }
};
const getFileName = (location: string | undefined): string => {
  const currentDate = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonth = monthNames[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();

  return `${location} ${currentMonth} ${currentYear} Forecast Report.pdf`;
};
