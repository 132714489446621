import { useTheme } from "@emotion/react";
import { UilTimes } from "@iconscout/react-unicons";
import { Button, Modal } from "antd";
import { Flex } from "components/Flex";
import { Text } from "components/Text";
import { ReportDownloadSuccess } from "components/TimeSeries/components/ReportDownloadSuccess";
import { ReportsCheckout } from "components/TimeSeries/components/ReportsCheckout";
import useDownloadReport from "hooks/useDownloadReport";
import { DownloadReportContext } from "hooks/useDownloadReportContext";
import { useUserData } from "hooks/useUser";
import { hasAccessToPremiumArea } from "lib/helpers/userHelpers";
import { ScoreConfig } from "lib/scoreBoard/scoreConfigs";
import moment from "moment";
import Image from "next/image";
import { useState } from "react";
import { Geo } from "types/MapContext";
import { ScoreDatapoints } from "types/cube";
import { DrawerTypes } from "../drawerTypes";
import useDrawer from "../useDrawer";
import ReportSpinnerModal from "components/reports/ReportSpinnerModal";

const text = {
  [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE]: {
    base: `Download the Reventure Home Price Forecast Report to view Reventure's
    predictions for the direction of home prices over the next 12 months in
    this area. The Reventure Forecast is based on analyzing underlying
    variables such as appreciation, inventory, price cuts, and mortgage rate
    movements to predict the future trends in prices.`,
  },
  [ScoreDatapoints.HOME_BUYER_SCORE]: {
    base: `Download the Reventure Home Buyer Forecast Report to view Reventure's
    predictions for the direction of home prices over the next 12 months in
    this area. The Reventure Forecast is based on analyzing underlying
    variables such as appreciation, inventory, price cuts, and mortgage rate
    movements to predict the future trends in prices.`,
  },
  [ScoreDatapoints.INVESTOR_SCORE]: {
    base: `Download the Reventure Home Price Forecast Report to view Reventure's
    predictions for the direction of home prices over the next 12 months in
    this area. The Reventure Forecast is based on analyzing underlying
    variables such as appreciation, inventory, price cuts, and mortgage rate
    movements to predict the future trends in prices.`,
  },
};

const DownloadReportDrawer = ({
  geo,
  geoCode,
  stateCode,
  scoreConfig,
}: {
  geo: Geo;
  geoCode: string;
  stateCode: string;
  scoreConfig: ScoreConfig;
}) => {
  const theme = useTheme();
  const user = useUserData();
  const { drawerType, showDrawer } = useDrawer();
  const { downloadReport, downloading } = useDownloadReport({
    geo,
    geoCode,
    stateCode,
    datapoint: scoreConfig.code,
  });

  const [openCheckoutModal, setOpenCheckoutModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const currentMonthYear = moment().format("MM_YYYY");
  const quotaUsed = user.premiumReportsQuotaUsed?.[currentMonthYear] || 0;

  const downloadLimit =
    user.planCycle === "Yearly"
      ? 60
      : parseInt(process.env.NEXT_PUBLIC_REPORT_DOWNLOAD_LIMIT as string);
  const planCycle = user.planCycle === "Yearly" ? "year" : "month";
  const userCanAccess =
    user.canAccessPremium ||
    hasAccessToPremiumArea(
      user,
      { state_code: stateCode, geo_code: geoCode },
      false,
      geo,
    );
  const userCanDownload =
    !user.isFreeUser && userCanAccess && quotaUsed < downloadLimit;

  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleDownloadClick = () => {
    if (userCanDownload) {
      console.log("User is going to download the report");
      setModalVisible(true);
      downloadReport(
        () => {
          showDrawer(null);
          setModalVisible(false);
          setOpenSuccessModal(true);
        },
        { hasPaidToDownload: false },
      );
    } else {
      showDrawer(null);
      setOpenCheckoutModal(true);
    }
  };

  return (
    <>
      <Modal
        destroyOnClose
        open={drawerType === DrawerTypes.DOWNLOAD_REPORT}
        footer={null}
        closable={false}
        zIndex={2000}
        style={{}}
        bodyStyle={{
          borderRadius: 10,
          padding: theme.padding.large,
        }}
      >
        <Flex
          justify="center"
          align="center"
          css={{
            position: "relative",
            width: "100%",
            height: 284,
            borderRadius: theme.radii.large,
            background:
              "linear-gradient(140deg, #FEF2FE 13.77%, #EDFEFE 85.44%)",
            backdropFilter: "blur(26.21212387084961px)",
          }}
        >
          <Flex
            css={{
              position: "absolute",
              top: 13,
              right: 13,
              marginBottom: theme.margin.large,
              background: theme.colors.white,
              borderRadius: theme.radii.small,
              cursor: "pointer",
              border: "1px solid rgba(0, 0, 0, 0.10)",
            }}
            onClick={() => showDrawer(null)}
          >
            <UilTimes width={32} height={32} />
          </Flex>

          <div>
            <Image
              src="/report-doc.png"
              alt="Report"
              width={192}
              height={239}
            />
          </div>
        </Flex>

        {process.env.NEXT_PUBLIC_REPORTS_COMING_SOON === "true" ? (
          <>
            <Text
              css={{
                fontSize: theme.fontSizes.heading2,
                fontWeight: theme.fontWeights.heading,
                lineHeight: "42px",
                letterSpacing: "-0.364px",
                textAlign: "center",
                marginTop: 16,
              }}
            >
              Reports Coming Soon
            </Text>
            <Text
              css={{
                fontSize: theme.fontSizes.large,
                fontWeight: theme.fontWeights.body,
                textAlign: "center",
              }}
            >
              Stay Tuned
            </Text>
          </>
        ) : (
          <>
            <Text
              css={{
                fontSize: theme.fontSizes.heading2,
                fontWeight: theme.fontWeights.heading,
                lineHeight: "42px",
                letterSpacing: "-0.364px",
              }}
            >
              {scoreConfig.label} Report
            </Text>
            <Text css={{ lineHeight: "22.433px", margin: "13px 0" }}>
              {text[scoreConfig.code].base}
            </Text>
            <Text css={{ lineHeight: "22.433px", marginBottom: 13 }}>
              {user.canAccessPremium
                ? `You have utilized ${quotaUsed} out of ${downloadLimit} Market Reports for the current ${planCycle}. `
                : ""}
              Click below to download the report immediately.
              {userCanDownload ? "" : ` The cost is a one-time payment of $19.`}
            </Text>
            <Button
              type="primary"
              loading={downloading}
              onClick={handleDownloadClick}
              css={{
                background: theme.colors.black,
                borderColor: theme.colors.black,
                color: theme.colors.white,
                marginTop: 6,
              }}
            >
              Download Report{userCanDownload ? "" : ` for $19`}
            </Button>
            <ReportSpinnerModal
              isVisible={isModalVisible}
              onClose={handleCloseModal}
            />
          </>
        )}
      </Modal>

      <DownloadReportContext.Provider
        value={{
          datapoint: scoreConfig.code,
          geoCode,
          stateCode,
          geo,
        }}
      >
        <ReportsCheckout
          open={openCheckoutModal}
          onClose={(openSuccess: boolean) => {
            if (typeof openSuccess === "boolean" && openSuccess) {
              setOpenSuccessModal(true);
            }
            setOpenCheckoutModal(false);
          }}
        />
      </DownloadReportContext.Provider>

      <ReportDownloadSuccess
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
      />
    </>
  );
};

export default DownloadReportDrawer;
