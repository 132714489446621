import { FC } from "react";

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const SearchIcon: FC<Props> = ({ width, height, color }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "25"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Search">
        <g id="Iconly/Light-Outline/Search">
          <g id="Search_2">
            <path
              id="Combined-Shape"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.31496 1.41675C12.1187 1.41675 15.2127 4.51075 15.2127 8.3145C15.2127 10.1091 14.524 11.7459 13.397 12.9743L15.6146 15.1872C15.8222 15.3948 15.8229 15.7305 15.6153 15.9381C15.5119 16.0429 15.3752 16.0946 15.2392 16.0946C15.1039 16.0946 14.9679 16.0429 14.8638 15.9395L12.6194 13.7014C11.4388 14.6469 9.94178 15.213 8.31496 15.213C4.51121 15.213 1.4165 12.1182 1.4165 8.3145C1.4165 4.51075 4.51121 1.41675 8.31496 1.41675ZM8.31496 2.47925C5.097 2.47925 2.479 5.09654 2.479 8.3145C2.479 11.5325 5.097 14.1505 8.31496 14.1505C11.5322 14.1505 14.1502 11.5325 14.1502 8.3145C14.1502 5.09654 11.5322 2.47925 8.31496 2.47925Z"
              fill={color || "#808080"}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SearchIcon;
