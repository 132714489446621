import { Geo } from "types/MapContext";
import { countyList } from "./countyList";
import { metroList } from "./metroList";
import { stateList } from "./stateList";
import { zipCountyMap } from "lib/constants";

class GeoNames {
  getFullName(geo: Geo, geoCode: string, stateCode?: string) {
    const list = {
      [Geo.STATE]: this.getFullNameForState,
      [Geo.METRO]: this.getFullNameForMetro,
      [Geo.COUNTY]: this.getFullNameForCounty,
      [Geo.ZIP]: this.getFullNameForZip,
    };
    const fn = list[geo];
    console.log(geoCode, stateCode);
    return fn(geoCode, stateCode);
  }

  getFullNameForState(stateCode: string) {
    const state = stateList.find((s) => s.value === stateCode);
    return state?.label || "";
  }
  getFullNameForMetro(metroCode: string) {
    const metro = metroList.find((m) => `${m["value"]}` === metroCode);
    return metro?.["label"] || "";
  }
  getFullNameForCounty(countyCode: string, stateCode?: string) {
    const county = countyList.find(
      (c) => c.value === countyCode || c.value === stateCode + countyCode,
    );
    if (!county) return "";
    return county.label.split(", ")[0].concat(", ", county.stateAbbr);
  }
  getFullNameForZip(zip: string) {
    if (zip in zipCountyMap) {
      return zip;
    }
  }
}

export default GeoNames;
