import React from "react";

interface SpinnerModalProps {
  isVisible: boolean;
  onClose: () => void;
  message?: string;
}

const ReportSpinnerModal: React.FC<SpinnerModalProps> = ({
  isVisible,
  onClose,
  message = "Report is being downloaded...",
}) => {
  if (!isVisible) return null;
  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalStyle: React.CSSProperties = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
    minWidth: "300px",
  };

  const spinnerStyle: React.CSSProperties = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderTop: "4px solid #fc4c64",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite",
  };

  const keyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  const buttonStyle: React.CSSProperties = {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#fc4c64",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };

  return (
    <>
      <style>{keyframes}</style>
      <div style={overlayStyle}>
        <div style={modalStyle}>
          <div style={spinnerStyle}></div>
          <p>{message}</p>
          <button style={buttonStyle} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default ReportSpinnerModal;
