import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { Text } from "components/Text";
import { ResponsiveContext } from "lib/context";
import { useContext } from "react";

export const ReportDownloadSuccess = ({ open, onClose }) => {
  const { isTabletOrMobile } = useContext(ResponsiveContext);

  return (
    <Modal isOpen={open} contentStyle={{ background: "white", minWidth: 350 }}>
      <Flex align="center" direction="column">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="61"
          height="61"
          viewBox="0 0 61 61"
          fill="none"
        >
          <path
            d="M30.4999 1.00014C46.7808 1.00021 59.9997 14.2192 59.9996 30.5001C59.9995 46.7811 46.7806 59.9999 30.4996 59.9999C14.2186 59.9998 0.999787 46.7808 0.999863 30.4999C0.999939 14.2189 14.2189 1.00006 30.4999 1.00014Z"
            fill="#33C023"
          />
          <path
            d="M30.4999 1.00014C46.7808 1.00021 59.9997 14.2192 59.9996 30.5001C59.9995 46.7811 46.7806 59.9999 30.4996 59.9999C14.2186 59.9998 0.999787 46.7808 0.999863 30.4999C0.999939 14.2189 14.2189 1.00006 30.4999 1.00014Z"
            stroke="#33C023"
            strokeWidth="1.19827"
          />
          <path
            d="M19.4102 33.2295L26.5828 40.4022L41.5953 25.3896"
            fill="#33C023"
          />
          <path
            d="M19.4102 33.2295L26.5828 40.4022L41.5953 25.3896"
            stroke="white"
            strokeWidth="5.33778"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Text
          css={{ marginTop: 17 }}
          fontSize={isTabletOrMobile ? "larger" : "heading1"}
          bolder
        >
          Download Successful
        </Text>
        <Button
          variant="primary"
          css={{ marginTop: 25, width: "100%" }}
          onClick={() => {
            onClose();
          }}
        >
          Continue
        </Button>
      </Flex>
    </Modal>
  );
};
