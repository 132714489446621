import { useTheme } from "@emotion/react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElements } from "@stripe/stripe-js";
import { Divider } from "antd";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Text } from "components/Text";
import { EditCard } from "components/billings/EditCard";
import ReportSpinnerModal from "components/reports/ReportSpinnerModal";
import useDownloadReport from "hooks/useDownloadReport";
import useDownloadReportContext from "hooks/useDownloadReportContext";
import useProgressQuery from "hooks/useProgressQuery";
import { useUserData } from "hooks/useUser";
import { getIconForCardType } from "lib/getIconForCardType";
import moment from "moment";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import PlanService from "services/PlanService";
import { createReportPayment } from "services/ReportService";

const ReportsPaymentElement = ({ onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const user = useUserData();
  const router = useRouter();
  const { progressQuery } = useProgressQuery();
  const { datapoint, geoCode, stateCode, geo } = useDownloadReportContext();
  const { downloadReport } = useDownloadReport({
    geo,
    geoCode,
    stateCode,
    datapoint,
  });

  const [errorMessage, setErrorMessage] = useState();
  const [downloading, setDownloading] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [cardUpdating, setCardUpdating] = useState<string>("");

  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const returnUrl = useMemo(() => {
    const sanitizedParams = Object.keys(progressQuery || {}).reduce(
      (prevValue, key) => {
        if (progressQuery?.[key]) {
          prevValue[key] = progressQuery[key];
        }
        return prevValue;
      },
      {},
    );
    const queryParams = new URLSearchParams(sanitizedParams).toString();
    return `${process.env.NEXT_PUBLIC_APP_URL}${router.pathname}?${queryParams}`;
  }, [progressQuery]);

  useEffect(() => {
    const paymentIntent = router.query.payment_intent;
    const paymentStatus = router.query.payment_status;
    const clientSecret = router.query.client_secret;

    if (
      paymentIntent &&
      paymentStatus === "succeeded" &&
      clientSecret &&
      user.uid
    ) {
      stripe
        ?.retrievePaymentIntent(clientSecret as string)
        .then(({ paymentIntent }) => {
          if (paymentIntent) {
            const currentTime = moment();
            const paymentIntentCreatedTime = moment(paymentIntent.created);
            const differenceInMinutes = currentTime.diff(
              paymentIntentCreatedTime,
              "minutes",
            );
            if (differenceInMinutes > 5) {
              downloadReport(() => {}, { hasPaidToDownload: true });
            }
          }
        });
    }
  }, [router.query]);

  const handleError = (error) => {
    setDownloading(false);
    setModalVisible(false);
    setErrorMessage(error.message);
  };

  const handleElementSubmit = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    if (!stripe || !user.uid) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setModalVisible(true);
    setDownloading(true);
    setProcessingPayment(true);
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements!.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }
    // Create the Subscription
    try {
      const { clientSecret } = await createReportPayment(user.id!);
      if (!clientSecret) {
        setDownloading(false);
        setProcessingPayment(false);
        return;
      }

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements: elements as StripeElements,
        clientSecret,
        redirect: "if_required",
        confirmParams: {
          return_url: `${returnUrl}`,
        },
      });

      if (paymentIntent?.status === "succeeded") {
        setErrorMessage(undefined);
        await PlanService.savePaymentMethod(
          user.id!,
          paymentIntent.payment_method as string,
        );
        setProcessingPayment(false);
        downloadReport(() => {}, { hasPaidToDownload: true });
        setModalVisible(false);
        onClose(true);
      }
      if (error) {
        handleError(error);
      }
      setDownloading(false);
    } catch (error: any) {
      handleError(error?.response?.data || error);
      setDownloading(false);
    }
  };

  const handlePaymentForUserWithCard = async () => {
    if (!stripe || !user.uid) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
      setModalVisible(true);
      setDownloading(true);
      setProcessingPayment(true);
      await createReportPayment(user.id!);
      setProcessingPayment(false);
      await downloadReport(() => {}, { hasPaidToDownload: true });
      onClose(true);
    } catch (error) {
      handleError(error);
    } finally {
      setProcessingPayment(false);
      setModalVisible(false);
      setDownloading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (user.paymentMethod) {
      handlePaymentForUserWithCard();
    } else {
      handleElementSubmit();
    }
  };

  return (
    <>
      <EditCard
        onClose={() => setShowEditModal(false)}
        showEditModal={showEditModal}
        user={user}
        setCardUpdating={setCardUpdating}
      />
      <form autoComplete="off" onSubmit={handleSubmit} css={{ width: "100%" }}>
        {user.paymentMethod ? (
          <div
            css={{
              border: `1px solid ${theme.colors.radioBorder}`,
              width: "100%",
              padding: "9px 11px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: theme.radii.smaller,
              marginBottom: 20,
            }}
          >
            {cardUpdating !== user?.paymentMethod?.last4 ? (
              <>
                <Flex gap={18} align="center">
                  <Image
                    alt="visa"
                    src={getIconForCardType(user.paymentMethod?.brand || "")}
                    width={40}
                    height={40}
                  />
                  <Flex gap={1} direction="column">
                    <Text>**** **** **** {user?.paymentMethod?.last4}</Text>
                    <Text>
                      {user.paymentMethod?.brand?.replace(
                        user.paymentMethod?.brand?.[0],
                        user.paymentMethod?.brand?.[0]?.toUpperCase(),
                      )}
                    </Text>
                  </Flex>
                </Flex>
                <Button
                  css={{
                    border: `1px solid ${theme.colors.radioBorder}`,
                    padding: "6px 12px",
                    background: "white",
                    borderRadius: theme.radii.smaller,
                    fontSize: theme.fontSizes.default,
                  }}
                  type="button"
                  onClick={() => setShowEditModal(true)}
                >
                  Edit
                </Button>
              </>
            ) : (
              <Text style={{ fontStyle: "italic", color: theme.colors.text3 }}>
                Updating card info...
              </Text>
            )}
          </div>
        ) : (
          <Flex flex="1" direction="column" css={{ width: "100%" }}>
            <Flex direction="column" css={{ width: "100%" }}>
              <PaymentElement
                options={{ terms: { card: "never" } }}
                css={{
                  fontSize: theme.fontSizes.default,
                  width: "100%",
                }}
                onChange={() => {
                  setErrorMessage(undefined);
                }}
              />
            </Flex>
            <Divider
              orientation="center"
              css={{
                height: 1,
                background: "#E2E2E2",
                width: "100%",
                marginTop: 30,
                marginBottom: 23,
              }}
            />
          </Flex>
        )}
        <div>
          <Flex justify="space-between">
            <Text css={{ opacity: 0.65 }}>Amount</Text>
            <Text>$19</Text>
          </Flex>
          <div
            css={{
              borderTop: `1px solid ${theme.colors.radioBorder}`,
              width: "100%",
              marginTop: 12,
            }}
          />
          <Flex justify="space-between" css={{ marginTop: 5 }}>
            <Text bolder>Total:</Text>
            <Text bolder>$19</Text>
          </Flex>
        </div>
        {errorMessage && (
          <Text
            css={{
              color: theme.colors.venetianRed,
              textAlign: "center",
              width: "100%",
              marginTop: 20,
            }}
            fontSize="medium"
          >
            {errorMessage}
          </Text>
        )}
        <Button
          css={{ width: "100%", marginTop: 14 }}
          disabled={downloading}
          variant="alternate"
        >
          {processingPayment
            ? "Processing Payment..."
            : downloading
              ? "Downloading..."
              : "Pay Now"}
        </Button>
        <ReportSpinnerModal
          isVisible={isModalVisible}
          onClose={handleCloseModal}
        />
      </form>
    </>
  );
};

export default ReportsPaymentElement;
